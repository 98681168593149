import 'src/libs/unhandledErrors';

// todo: move
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// to fix TypeError: Do not know how to serialize a BigInt
BigInt.prototype.toJSON = function(){
    return this.toString();
}

import '@gravity-ui/uikit/styles/fonts.css';
import '@gravity-ui/uikit/styles/styles.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'antd/dist/reset.css';
import './overrides.css';
import './app.scss';

import React, {
    useEffect,
    useState,
} from 'react';
import {
    createRoot,
} from 'react-dom/client';
import {
    Provider,
} from 'react-redux';
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from 'react-router-dom';

import UsersStore from 'src/stores/Users';
import {
    fetchPresets,
} from 'src/stores/fetchPresets';
import {
    issueSessionByToken,
}  from 'src/dataProviders/users';

import {
    FrontendUrls,
} from 'src/constants/url';

import store from 'src/reduxStore/reducers';

import Loading from 'skd-ui/Loading/Loading';
import Alerts from 'src/components/Alerts/Alerts';
import InternetConnectionStatus from 'src/components/core/InternetConnectionStatus/InternetConnectionStatus';
import CheckTranslatePage from 'src/components/core/CheckTranslatePage/CheckTranslatePage';

import NotFind from 'src/controllers/NotFind/NotFind';
import Api from 'src/controllers/Api/Api.loadable';
import Home from 'src/controllers/Home/Home.loadable';
import LogIn from 'src/controllers/LogIn/LogIn.loadable';
import SignUp from 'src/controllers/SignUp/SignUp.loadable';
import Accounting from 'src/controllers/Accounting/Accounting.loadable';
import Users from 'src/controllers/Users/Users.loadable';
import User from 'src/controllers/User/User.loadable';
import Order from 'src/controllers/Order/Order.loadable';
import Orders from 'src/controllers/Orders/Orders.loadable';
import Help from 'src/controllers/Help/Help.loadable';
import Map from 'src/controllers/Map/Map.loadable';

import Toolbar from 'src/components/Toolbar/Toolbar';

import {
    ThemeProvider,
} from '@gravity-ui/uikit';

const mount = document.getElementById('mount');
const alerts = document.getElementById('alerts');
const internetConnectionStatus = document.getElementById('internetConnectionStatus');
const checkTranslatePage = document.getElementById('checkTranslatePage');

const mountRoot = createRoot(mount);
const alertsRoot = createRoot(alerts);
const internetConnectionStatusRoot = createRoot(internetConnectionStatus);
const checkTranslatePageRoot = createRoot(checkTranslatePage);

function AppWithCallbackAfterRender() {
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        async function fetchData() {
            try {
                const url = new URL(document.location.toString());
                const interimToken = url.searchParams.get('interimToken');
                if (interimToken) {
                    await issueSessionByToken({token: interimToken});
                }
                await UsersStore.getCurrentUser();
                void fetchPresets();
            } finally {
                setIsLoaded(true);
            }
        }

        fetchData();
    }, []);

    useEffect(() => {
        function checkUserSession() {
            UsersStore.getCurrentUser();
        }

        let lastTimeTabVisible = Date.now();
        function onVisibilityChange() {
            if (document.hidden){
                console.log('Browser tab is hidden');
            } else {
                if (Date.now() - lastTimeTabVisible > 1000 * 60 * 5) {
                    checkUserSession();
                }
                console.log('Browser tab is visible');
                lastTimeTabVisible = Date.now();
            }
        }

        const timer = setInterval(() => checkUserSession, 1000 * 60 * 10);
        document.addEventListener('visibilitychange', onVisibilityChange);

        return () => {
            clearInterval(timer);
            document.removeEventListener('visibilitychange', onVisibilityChange);
        };
    }, []);

    if (isLoaded) {
        return (
            <Provider store={store}>
                <ThemeProvider theme="light">
                    <Router>
                        <Toolbar/>
                        <Routes>
                            <Route path={FrontendUrls.Home}>
                                <Route index={true} element={<Home/>}/>
                                <Route path={FrontendUrls.Api} element={<Api/>}/>
                                <Route path={FrontendUrls.LogIn} element={<LogIn/>}/>
                                <Route path={FrontendUrls.SignUp} element={<SignUp/>}/>
                                <Route path={FrontendUrls.Users} element={<Users/>}/>
                                <Route path={FrontendUrls.User} element={<User/>}/>
                                <Route path={FrontendUrls.Orders} element={<Orders/>}/>
                                <Route path={FrontendUrls.Order} element={<Order/>}/>
                                <Route path={FrontendUrls.Accounting} element={<Accounting/>}/>
                                <Route path={FrontendUrls.AccountingLink} element={<Accounting/>}/>
                                <Route path={FrontendUrls.Help} element={<Help/>}/>
                                <Route path={FrontendUrls.Map} element={<Map/>}/>
                                <Route path={FrontendUrls.NotFind} element={<NotFind/>}/>
                            </Route>
                        </Routes>
                    </Router>
                </ThemeProvider>
            </Provider>
        );
    }

    return <Loading/>;
}

mountRoot.render(<AppWithCallbackAfterRender />);
alertsRoot.render((
    <Provider store={store}>
        <Alerts/>
    </Provider>
));
internetConnectionStatusRoot.render((
    <InternetConnectionStatus/>
));
checkTranslatePageRoot.render((
    <CheckTranslatePage/>
));
