export enum WarehouseType {
    // склад с пунктом выдачи
    PVZ = 'pvz',
    // склад без пункта выдачи
    Skd = 'skd',
    // склад заказчика
    Customer = 'customer',
}

export const VALID_WAREHOUSE_TYPE_VALUES = Object.values(WarehouseType);

export enum Field {
    WarehouseId = 'warehouse.id',
    WarehouseTypeEnum = 'warehouse.typeEnum',
    WarehouseName = 'warehouse.name',
    WarehouseAddress = 'warehouse.address',
    WarehouseSubway = 'warehouse.subway',
    WarehouseLatitude = 'warehouse.latitude',
    WarehouseLongitude = 'warehouse.longitude',
    WarehouseSubwayDistanceKilometers = 'warehouse.subwayDistanceKilometers',

    WarehousePhone = 'warehouse.phone',
    WarehouseComment = 'warehouse.comment',
    WarehouseIsRemoved = 'warehouse.isRemoved',

    WarehousePvzWarehouseId = 'pvz.warehouseId',

    WarehouseSKDWarehouseId = 'skd.warehouseId',

    WarehouseCustomerWarehouseId = 'customer.warehouseId',
    WarehouseCustomerCustomerUserId = 'customer.customerUserId',
}

export const VALID_WAREHOUSE_FIELD_VALUES = Object.values(Field);
